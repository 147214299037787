import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import photo from "../assets/ppl_talking_compress.jpeg";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	gap: 20px;
`;

const Header = styled.div`
	font-family: "Nunito", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 45px;
	color: #a979f8;
	text-align: center; /* Center the text */
	margin-top: 20px;
`;

const Paragraph = styled.div`
	font-family: "Nunito", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	color: #757575;
	text-align: center;
`;

const Illustration = styled.img`
	width: 90vw;

	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) {
		width: 70vw;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		width: 50vw;
	}

	/* Extra large devices (large desktops, 1200px and up) */
	@media (min-width: 1200px) {
		width: 30vw;
	}
`;

const Button = styled.button`
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	/* Position and size */
	width: 333px;
	height: 56px;
	margin: auto; /* Center horizontally with margin */

	/* Background and border */
	background: #a979f8;
	box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 30px;
	border: none; /* Remove default border */

	/* Text styling */
	font-family: "Nunito", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 19px;
	line-height: 26px;
	color: #ffffff;
	cursor: pointer; /* Change cursor to pointer on hover */
	text-align: center; /* Center text inside the button */

	/* Adjustments for hover and active states */
	&:hover {
		background: #8b5fc6; /* Slightly darker on hover */
	}
	&:active {
		background: #764ab8; /* Even darker when active */
	}
`;

const LandingPage = () => {
	const navigate = useNavigate();

	const startSession = () => {
		navigate("/ask");
	};

	return (
		<Container>
			<Header>Improve Your Game</Header>
			<Paragraph>
				Online dating sucks, but you’ll always have to deal with the
				small talk in meeting someone. You’ll be surprised how far a
				good conversation can go. We help with that.
			</Paragraph>
			<Illustration src={photo} alt='Illustration' />
			<Button onClick={startSession}>Ask Away</Button>
		</Container>
	);
};

export default LandingPage;
