import styled from "styled-components";
import React from "react";

const Image = styled.img`
	max-width: 40%;
	border-radius: 10px;
	margin-top: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;

// Styled components
const ChatMessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${({ $isUser }) => ($isUser ? "flex-end" : "flex-start")};
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
`;

const Message = styled.div`
	background-color: ${({ $isUser }) => ($isUser ? "#a979f8" : "#f1f1f1")};
	color: ${({ $isUser }) => ($isUser ? "white" : "black")};
	border-radius: 15px; /* More rounded corners */
	padding: 12px; /* Increased padding for better spacing */
	max-width: 75%;
	white-space: pre-wrap;
	word-wrap: break-word;
	line-height: 1.5; /* Increased line height for better readability */
	font-family: "Nunito", sans-serif;
	font-weight: 300;
	font-size: 16px;
	text-align: left;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
	transition: background-color 0.3s; /* Smooth transition */

	&:hover {
		background-color: ${({ $isUser }) =>
			$isUser ? "#8b5fc6" : "#e0e0e0"}; /* Slightly darker on hover */
	}
`;

const FlirtanaIcon = styled.img`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: -20px;
`;

const BoldText = styled.span`
	font-weight: bold;
`;

const ItalicText = styled.span`
	font-style: italic;
`;

const BlockCode = styled.pre`
	background-color: #f4f4f4;
	padding: 10px;
	border-radius: 4px;
	font-family: monospace;
	white-space: pre-wrap;
	margin: 10px 0;
	line-height: 1.25;
`;

// Function to parse text and return an array of elements
const parseText = (text) => {
	const parts = text.split(/(\*\*[^*]+\*\*|\*[^*]+\*|```[^```]+```)/g);
	return parts.map((part, index) => {
		if (part.startsWith("**") && part.endsWith("**")) {
			return <BoldText key={index}>{part.slice(2, -2)}</BoldText>;
		}
		if (part.startsWith("*") && part.endsWith("*")) {
			return <ItalicText key={index}>{part.slice(1, -1)}</ItalicText>;
		}
		if (part.startsWith("```") && part.endsWith("```")) {
			return <BlockCode key={index}>{part.slice(3, -3)}</BlockCode>;
		}
		return part;
	});
};

const ChatMessage = ({ message }) => {
	return (
		message.content.text !== "" && (
			<ChatMessageContainer $isUser={message.isUser}>
				{message.content.type === "image" && (
					<Image src={message.content.url} alt='User uploaded' />
				)}
				{message.content.type === "text" && (
					<Message $isUser={message.isUser}>
						{parseText(message.content.text)}
					</Message>
				)}

				{!message.isUser && (
					<FlirtanaIcon src='/flirtana_icon_120.png' alt='Flirtana' />
				)}
			</ChatMessageContainer>
		)
	);
};

export default ChatMessage;
