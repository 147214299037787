import React from "react";
import styled, { keyframes } from "styled-components";

const PageLoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
	border: 8px solid #f3f3f3; /* Light grey */
	border-top: 8px solid #a979f8; /* Purple */
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: ${spin} 2s linear infinite;
`;

const StreamLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 5px 0;
`;

const pulse = keyframes`
	0% { transform: scale(1); }
	50% { transform: scale(1.5); }
	100% { transform: scale(1); }
`;

const Pulse = styled.div`
	width: 20px;
	height: 20px;
	background-color: #f1f1f1;
	border-radius: 50%;
	animation: ${pulse} 1s infinite;
`;

const LoadingIndicator = ({ isPageLoading }) =>
	isPageLoading ? (
		<PageLoadingContainer>
			<Spinner />
		</PageLoadingContainer>
	) : (
		<StreamLoadingContainer>
			<Pulse />
		</StreamLoadingContainer>
	);

export default LoadingIndicator;
