import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import ChatMessage from "./ChatMessage";
import LoadingIndicator from "./LoadingIndicator";

const ChatContainer = styled.div`
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: auto;
	height: calc(100vh - 20px); /* Adjust height to fit the viewport */
	position: relative; /* Ensure it is positioned relative to the viewport */
	padding-left: 15px;
`;

const InputContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	font-family: "Nunito";
	margin-top: 10px;
	background-color: #fff; /* White background for input area */
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
	position: sticky; /* Use sticky position */
	bottom: 20px; /* Stick to the bottom */
	margin-bottom: 10px; /* Add margin to prevent overlap */
	z-index: 1000; /* Ensure it is above other elements */
`;

const UploadButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 85px;
	height: 60px;
	background: #f4f4f4;
	box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.25);
	border-radius: 30px;
	cursor: pointer;
	transition: background 0.3s; /* Smooth transition */

	&:hover {
		background: #e0e0e0; /* Slightly darker on hover */
	}
`;

const SubmitButton = styled.button`
	padding: 10px;
	background-color: #a979f8;
	color: white;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	transition: background 0.3s; /* Smooth transition */

	&:hover {
		background-color: #8b5fc6; /* Slightly darker on hover */
	}
`;

const NewSessionButton = styled.button`
	padding: 10px;
	width: 50%;
	align-self: center;
	background-color: #6a67ce;
	color: white;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	margin-top: 10px;
	transition: background 0.3s; /* Smooth transition */

	&:hover {
		background-color: #5a57b0; /* Slightly darker on hover */
	}
`;

const InitStateContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const UploadScreenshotContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 326px;
	height: 200px;
	gap: 12px;
	background: #ffffff;
	border-radius: 40px;
	padding: 20px;
	text-align: center;
`;

const UploadTitle = styled.div`
	font-family: "Nunito";
	font-weight: 700;
	font-size: 22px;
	color: #3f3f3f;
`;

const UploadDescription = styled.div`
	font-family: "Nunito";
	font-weight: 500;
	font-size: 14px;
	color: #3e3e3e;
`;

const UploadIcon = styled.div`
	width: 50px;
	height: 50px;
	background: url("/upload_img_icon.png") no-repeat center center;
	background-size: contain;
	image-rendering: smooth;
`;

const ImagePreviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-top: 10px;
`;

const ImagePreview = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 5px;
	border: 1px solid #ddd;
`;

const AddDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 326px;
	gap: 18px;
	background: #ffffff;
	border-radius: 40px;
	padding: 20px;
	text-align: center;
`;

const DetailIcon = styled.div`
	width: 50px;
	height: 50px;
	background: url("/question_icon.png") no-repeat center center;
	background-size: contain;
	image-rendering: smooth;
`;

const DetailTitle = styled.div`
	font-family: "Nunito";
	font-weight: 700;
	font-size: 20px;
	color: #3f3f3f;
`;

const AddContextButton = styled.button`
	width: 240px;
	height: 48px;
	background: #a979f8;
	border-radius: 24px;
	border: none;
	font-family: "Nunito";
	font-weight: 700;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
`;

const AddContextInput = styled.div`
	width: 234px;
	height: 42px;
	background: #a979f8;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px;

	input {
		width: 100%;
		height: 100%;
		background: #ffffff;
		border: none;
		border-radius: 30px;
		text-align: center;
		font-family: "Nunito";
		font-weight: 700;
		font-size: 14px;
		color: #3f3f3f;
		padding: 0 10px;
		outline: none; /* Remove outline on focus */
	}
`;

const FileInput = styled.input`
	margin: 5px 0;
`;

const TextInput = styled.input`
	width: 95%;
	height: 20px; /* Set a fixed height */
	padding: 10px; /* Adjust padding */
	border-radius: 30px;
	font-family: "Nunito", sans-serif;
	font-size: 16px;
	border: 1px solid #ddd;
`;

const AttachFileButton = styled.div`
	width: 30px;
	height: 30px;
	background: url("/attach-paperclip-symbol.png") no-repeat center center;
	background-size: contain;
	cursor: pointer;
	margin-left: 2px;
	margin-right: -5px;
`;

const ChatWindow = () => {
	const [messages, setMessages] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [question, setQuestion] = useState("");
	const [pageLoading, setPageLoading] = useState(false);
	const [streamLoading, setStreamLoading] = useState(false);
	const [sessionId, setSessionId] = useState(
		localStorage.getItem("sessionId") || null
	);
	const [submitTriggered, setSubmitTriggered] = useState(false);

	useEffect(() => {
		if (sessionId !== null && sessionId !== "") {
			fetchMessages();
		} else {
			const newSessionId = uuidv4();
			setSessionId(newSessionId);
			localStorage.setItem("sessionId", newSessionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchMessages = async () => {
		setPageLoading(true);
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/get-messages?sessionId=${sessionId}`
			);
			if (response.ok) {
				const sessionHistory = await response.json();
				const newMessages = sessionHistory
					.map((message) => {
						if (message.role === "assistant") {
							return {
								isUser: false,
								content: {
									type: "text",
									text: message.content
								}
							};
						} else {
							return message.content.map((content) => {
								if (content.type === "image_url") {
									return {
										isUser: true,
										content: {
											type: "image",
											url: content.image_url.url
										}
									};
								} else {
									return {
										isUser: true,
										content: {
											type: "text",
											text: content.text
										}
									};
								}
							});
						}
					})
					.flat();
				setMessages(newMessages);
			}
		} catch (error) {
			console.error("Error fetching messages:", error);
		}
		setPageLoading(false);
	};

	const addMessage = (newMessage) => {
		setMessages((prevMessages) => [...prevMessages, newMessage]);
	};

	const setStreamText = (message) => {
		setMessages((prevMessages) => {
			const updatedMessages = [...prevMessages];
			if (updatedMessages.length === 0) return updatedMessages;
			updatedMessages[updatedMessages.length - 1].content.text = message;
			return updatedMessages;
		});
	};

	const handleSubmit = async () => {
		if (selectedFiles.length === 0 && messages.length === 0) {
			alert("Please upload a screenshot or bio to begin!");
			return;
		}
		setPageLoading(false);
		setStreamLoading(true);

		const formData = new FormData();
		formData.append("sessionId", sessionId);
		formData.append("prompt", question);
		selectedFiles.forEach((file) => {
			formData.append("images", file);
			addMessage({
				isUser: true,
				content: {
					type: "image",
					url: URL.createObjectURL(file)
				}
			});
		});
		addMessage({
			isUser: true,
			content: {
				type: "text",
				text: question
			}
		});

		try {
			setQuestion("");
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/ask`,
				{
					method: "POST",
					body: formData
				}
			);
			const reader = response.body.getReader();
			const decoder = new TextDecoder("utf-8");
			let done = false;
			let message = "";
			let firstCharReceived = false;
			addMessage({
				isUser: false,
				content: {
					type: "text",
					text: message
				}
			});
			while (!done) {
				const { value, done: doneReading } = await reader.read();
				done = doneReading;
				message += decoder.decode(value, { stream: true });
				if (!firstCharReceived && message.length > 0) {
					firstCharReceived = true;
					setStreamLoading(false);
				}
				setStreamText(message);
			}
			setSelectedFiles([]);
		} catch (error) {
			console.error("Error uploading images:", error);
		}
		setStreamLoading(false);
	};

	useEffect(() => {
		if (submitTriggered) {
			handleSubmit();
			setSubmitTriggered(false); // Reset the flag
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitTriggered]);

	const startConversation = async (defaultQuestion) => {
		setQuestion(defaultQuestion);
		setSubmitTriggered(true);
	};

	const createNewSession = () => {
		const newSessionId = uuidv4();
		setSessionId(newSessionId);
		localStorage.setItem("sessionId", newSessionId);
		setMessages([]);
		setQuestion("");
	};

	return (
		<ChatContainer>
			{pageLoading ? (
				<LoadingIndicator isPageLoading={pageLoading} />
			) : messages.length === 0 ? (
				<InitStateContainer>
					<UploadScreenshotContainer>
						<UploadTitle>Provide a Screenshot</UploadTitle>
						<UploadDescription>
							Add 1+ screenshots of their profile or your
							conversation history.
						</UploadDescription>
						<UploadButton
							onClick={() =>
								document
									.getElementById("hiddenFileInput")
									.click()
							}
						>
							<FileInput
								id='hiddenFileInput'
								type='file'
								multiple
								style={{ display: "none" }}
								onChange={(e) =>
									setSelectedFiles(Array.from(e.target.files))
								}
							/>
							<UploadIcon />
						</UploadButton>
						{selectedFiles.length ? (
							<>
								<div style={{ fontFamily: "Nunito" }}>
									Uploaded Images:
								</div>
								<ImagePreviewContainer>
									{selectedFiles.map((file, index) => (
										<ImagePreview
											key={index}
											src={URL.createObjectURL(file)}
										/>
									))}
								</ImagePreviewContainer>
							</>
						) : null}
					</UploadScreenshotContainer>
					<AddDetailsContainer>
						<DetailIcon />
						<DetailTitle>Add Details & Ask</DetailTitle>
						<AddContextButton
							onClick={() => startConversation("good openers?")}
						>
							Start conversation
						</AddContextButton>
						<AddContextButton
							onClick={() =>
								startConversation("how to continue from here?")
							}
						>
							Continue conversation
						</AddContextButton>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<AddContextInput>
								<input
									type='text'
									placeholder='Other questions'
									value={question}
									onChange={(e) =>
										setQuestion(e.target.value)
									}
								/>
							</AddContextInput>
						</form>
					</AddDetailsContainer>
				</InitStateContainer>
			) : (
				<>
					{messages.map((message, index) => (
						<ChatMessage key={index} message={message} />
					))}
					{streamLoading && (
						<LoadingIndicator isPageLoading={pageLoading} />
					)}
					<NewSessionButton onClick={createNewSession}>
						Create New Session
					</NewSessionButton>
					<InputContainer>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
							style={{ width: "100%" }}
						>
							<TextInput
								value={question}
								onChange={(e) => setQuestion(e.target.value)}
								placeholder='Write your message'
							/>
						</form>
						<AttachFileButton
							onClick={() =>
								document
									.getElementById("hiddenFileInput")
									.click()
							}
						>
							<FileInput
								id='hiddenFileInput'
								type='file'
								multiple
								style={{ display: "none" }}
								onChange={(e) =>
									setSelectedFiles(Array.from(e.target.files))
								}
							></FileInput>
						</AttachFileButton>
						<SubmitButton onClick={handleSubmit}>Send</SubmitButton>
					</InputContainer>
				</>
			)}
		</ChatContainer>
	);
};

export default ChatWindow;
